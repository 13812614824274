/* eslint-disable array-callback-return */
import { roundPrice } from 'Util/Price';
import { getCountryAndLanguageCode } from 'Util/Url';

export const INTERVAL = 3000;

/** @namespace Seedsman/Util/Insider/Index/fireInsiderEvent */
export const fireInsiderEvent = (eventType, customerData = {}, isPersistent = true) => {
    if (!customerData) {
        window.insider_object = {};
        return;
    }

    const {
        id, email, date_of_birth, is_subscribed, firstname, lastname
    } = customerData;

    const { countryCode, languageCode } = getCountryAndLanguageCode();

    const eventData = {
        uuid: `${id}`,
        birthday: date_of_birth,
        gdpr_optin: true,
        name: firstname,
        surname: lastname,
        email,
        email_optin: is_subscribed,
        language: `${languageCode}-${countryCode !== 'uk' ? countryCode : 'gb'}`,
        custom: {
            outdoor_grow_guide: '',
            new_release_sign_up_email: '',
            new_release_sign_up: ''
        }
    };

    if (window.Insider && typeof window.Insider === 'object') {
        window.insider_object = { [eventType]: eventData };
    } else if (isPersistent) {
        setTimeout(() => {
            fireInsiderEvent(eventType, customerData, false);
        }, INTERVAL); // try again in 3 seconds
    }
};

/** @namespace Seedsman/Util/Insider/Index/fireInsiderPageEvent */
export const fireInsiderPageEvent = (pageType, cartTotals) => {
    const cart = {
        currency: cartTotals?.quote_currency_code || '',
        total: cartTotals?.base_grand_total || 0,
        shipping_cost: cartTotals?.shipping_amount || 0,
        line_items: []
    };
    const cartItems = cartTotals?.items;
    console.log(cartItems, 'Insider cart items');
    if (cartItems) {
        cartItems.map((item) => {
            const productItems = item?.product;
            let productVariantId = 0;
            let productVariantName = '';
            let unit_sale_price_total = 0;
            if (productItems) {
                const productVariant = productItems?.variants;
                if (productVariant.length > 0) {
                    productVariant.map((item) => {
                        productVariantId = item?.id;
                        productVariantName = item?.name;
                    });
                }
                unit_sale_price_total = roundPrice((item?.price) - (item?.discount_amount));
                cart.line_items.push({
                    product: {
                        id: productVariantId || productItems?.id,
                        name: productVariantName || productItems?.name || '',
                        product_image_url: productItems?.thumbnail?.url || '',
                        currency: productItems?.price_range?.maximum_price?.regular_price?.currency || '',
                        unit_sale_price: unit_sale_price_total || 0,
                        unit_price: productItems?.price_range?.maximum_price?.regular_price?.value || 0,
                        url: window?.location?.origin + productItems?.url || '',
                        subtotal: roundPrice(unit_sale_price_total * item?.qty) || 0,
                        quantity: item?.qty
                    }
                });
            }
        });
    }
    if (window.Insider && typeof window.Insider === 'object') {
        window.insider_object = {
            page: {
                type: pageType
            },
            basket: {
                ...cart
            }
        };
    }
};

/** @namespace Seedsman/Util/Insider/Index/fireSdktracker */
export const fireSdktracker = (data) => {
    console.log(data, 'fireSdktracker');
    const {
        order_id, unique_id, sale_amount, tax_amount, shipping_amount,
        type, currency
    } = data;

    if (window.trackerApplet && typeof window.trackerApplet === 'object') {
        // eslint-disable-next-line max-len
        console.log(window.trackerApplet.makeRequest(order_id, unique_id, sale_amount, tax_amount, shipping_amount, type, currency, 'fireSdktracker'));
    } else {
        console.log('not triggering');
    }
};
